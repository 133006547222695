// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UL-kSvgE{align-items:center;background-image:linear-gradient(to right,var(--gradient-from),var(--gradient-to));border-radius:20px;display:inline-flex;justify-content:center;position:relative}.UL-kSvgE:before{background-color:var(--color-body);border-radius:18px;bottom:0;content:\"\";left:0;margin:2px;position:absolute;right:0;top:0;transition:opacity .35s ease;z-index:0}.UL-kSvgE:hover:before{opacity:0}.UL-kSvgE:active,.UL-kSvgE:active:before{background-image:linear-gradient(90deg,#4162ff,#0537ff)}.UL-kSvgE:active:before{opacity:1}.LZMQPbLa{align-items:center;color:var(--color-text);display:flex;font-size:18px;justify-content:center;padding:18px 30px;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"button": "UL-kSvgE",
	"shadow": "LZMQPbLa"
};
module.exports = ___CSS_LOADER_EXPORT___;
