import { render, staticRenderFns } from "./LandingDemoParticipate.vue?vue&type=template&id=12827878"
import script from "./LandingDemoParticipate.vue?vue&type=script&lang=js"
export * from "./LandingDemoParticipate.vue?vue&type=script&lang=js"
import style0 from "./LandingDemoParticipate.vue?vue&type=style&index=0&id=12827878&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingDemoButton: require('/app/components/landing/demo/LandingDemoButton.vue').default})
