// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kVaqj\\+IO{display:flex;margin:0 0 40px;position:relative}@media(min-width:667.98px){.kVaqj\\+IO{flex-wrap:wrap;margin:0 0 100px}}.kVaqj\\+IO:before{background-image:linear-gradient(180deg,#4162ff,#b48408);bottom:0;content:\"\";left:-16px;position:absolute;right:-16px;top:0}@media(min-width:667.98px){.kVaqj\\+IO:before{background-image:linear-gradient(90deg,#4162ff 25%,#b48408 75%);left:-50vw;right:-50vw}}.hXj9o-I0{display:flex;max-width:1400px;position:relative}@media(min-width:667.98px){.hXj9o-I0{flex-wrap:wrap}.ayzGdDII{flex-basis:50%}}.bKgDVCM8{padding-bottom:24px}@media(min-width:667.98px){.bKgDVCM8{padding-bottom:40px}}@media(min-width:947.98px){.bKgDVCM8{padding-bottom:150px}}.CerHoqQU{display:none}@media(min-width:667.98px){.CerHoqQU{align-items:flex-end;display:flex}}.aVcLrvZv{height:auto;margin:24px 0}@media(min-width:667.98px){.aVcLrvZv{margin:12% 0 32px;max-width:544px;width:80%}}@media(min-width:947.98px){.aVcLrvZv{margin:17% 0 32px}}.Y84yJbBF{font-size:max(18px,min(2vw,24px));margin:0;padding:0 0 32px}@media(min-width:947.98px){.Y84yJbBF{padding:0 0 42px;width:80%}}.rEpdgBOI{display:none}@media(min-width:667.98px){.rEpdgBOI{display:flex;max-height:700px;max-width:800px;transition:width .15s,height .15s}}.\\+\\+21hsl1{background-color:var(--gradient-to);background-image:none!important;transition:filter .35s}.\\+\\+21hsl1:before{background-color:transparent!important}.\\+\\+21hsl1 span{color:var(--color-body)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "kVaqj+IO",
	"row": "hXj9o-I0",
	"cell": "ayzGdDII",
	"cellTypo": "bKgDVCM8",
	"cellImage": "CerHoqQU",
	"typo": "aVcLrvZv",
	"text": "Y84yJbBF",
	"image": "rEpdgBOI",
	"button": "++21hsl1"
};
module.exports = ___CSS_LOADER_EXPORT___;
